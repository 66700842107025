@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.container {
  display: flex;
  min-width: 320px;
  width: 100%;
  gap: 3px;
  align-items: center;
  justify-content: center;
  padding: 5.5px 4px;
  background: var(--global-white);
  box-shadow: var(--elevation-3);

  .tabs-container {
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 3px;
    max-width: 1023px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include min-428-break {
      gap: 0;
    }
  }

  @include min-428-break {
    padding: 6px 4px;
  }

  @include min-744-break {
    padding: 14px;
  }
}

.item {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2px;
  justify-content: center;
  align-items: center;

  @include min-428-break {
    gap: 4px;
  }

  &.active {
    .bottom-slot {
      &--title,
      &--icon {
        color: var(--cst-tabitem-profile-icon-active);
      }
    }
  }

  .top-slot {
    display: flex;
    position: relative;
    @include fixedSize(22px, 22px);

    @include min-428-break {
      @include fixedSize(28px, 28px);
    }
  }

  .bottom-slot {
    &--title {
      color: var(--cst-tabitem-profile-text-default);
      @include typography-xs;
      @include weight-semi-bold;

      @include min-428-break {
        @include typography-s;
      }
    }

    &--icon {
      color: var(--global-grey700);
      @include singleFixedSize(22px);

      @include min-428-break {
        @include singleFixedSize(28px);
      }
    }
  }
}
